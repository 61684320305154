

/*=================================================================
  Pricing section
==================================================================*/

.pricing-table {
  padding:100px 0;
  padding-bottom: 70px;
  background: url('../images/pricing-bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  .title {
    p {
      color: $light;
    }
  }
  &:before {
    background: rgba(0, 0, 0, 0.62);
    position: absolute;
    z-index: 999;
    content: '';
    top: 0;
    left: 0;
    right:0;
    bottom:0;
  }
  .container {
    position: relative;
    z-index: 999;
  }
  .pricing-item {
    padding: 40px 55px 65px;
    background: $light;
    margin-bottom: 30px;
    a.btn-main {
      text-transform: uppercase;
      margin-top: 20px;
    }
     li {
      font-weight: 400;
      padding: 10px 0;
      color:#666;
      i {
        margin-right: 6px;
      }
    }
  }
  .price-title {
    padding: 30px 0 20px;
     > h3 {
      font-weight: 700;
      margin: 0 0 5px;
      font-size: 15px;
      text-transform: uppercase;
    }
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 5px;
    }
    .value {
      color: $primary-color;
      font-size: 50px;
      padding: 10px 0;
    }
  }
 
}


