/*=================================================================
  Contact
  ==================================================================*/
.contact-us {
  padding: 100px 0 50px 0;
}

.contact-form {
  margin-bottom: 40px;

  .form-control {
    background-color: transparent;
    border: 1px solid #dedede;
    box-shadow: none;
    height: 45px !important;
    color: #0c0c0c;
    height: 38px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    border-radius: 0;
  }

  input:hover,
  textarea:hover,
  #contact-submit:hover {
    border-color: $primary-color;
  }

  #contact-submit {
    border: none;
    padding: 15px 0;
    width: 100%;
    margin: 0;
    background: $primary-color;
    color: $light;
    border-radius: 0;
  }

  textarea.form-control {
    padding: 10px;
    height: 120px !important;
    outline: none;
  }
}

.contact-details {
  .contact-short-info {
    margin-top: 15px;

    li {
      margin-bottom: 6px;
    }
  }
}

.social-icon {
  margin-top: 20px;

  ul {
    li {
      display: inline-block;
      margin-right: 10px;

      a {
        display: block;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid #dedede;
        text-align: center;

        &:hover {
          background: $primary-color;
          color: $light;
          border: 1px solid $primary-color;

          i {
            color: $light;
          }
        }

        i {
          color: #55606a;
          display: inline-block;
          font-size: 20px;
          line-height: 50px;
          margin: 0;
        }
      }
    }
  }
}

.google-map {
  position: relative;

  .map_canvas {
    height: 400px;
    color: white;
  }
}